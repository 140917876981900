import React, { useEffect, useRef, useState } from 'react';

import { default as NavMan } from '../../helpers/NavigationManager';
import { default as ContentPages } from '../../helpers/ContentPages';

import './PageContent.scss';

import AssetLoader from '../../helpers/AssetLoader';
import ContentLink from '../content/contentlink/ContentLink';
import PdfViewer from '../content/pdfviewer/PdfViewer';
import RelativeSitemap from '../relativesitemap/RelativeSitemap';
import MarkdownDisplay from '../markdowndisplay/MarkdownDisplay';
import MarkdownEditor from '../markdowneditor/MarkdownEditor';
import AuthorInfo from '../authorinfo/AuthorInfo';
import Feedback from '../feedback/Feedback';
import VideoCatalog from '../videocatalog/VideoCatalog';
import ContentImage from '../image/ContentImage';
import { scrapeMarkdownHeadings } from '../../helpers/atoms/inPageHeadings';
import { AddPage, areToolboxItemsEquivalent, removeItem, toolboxPageItemsAtom } from '../../helpers/atoms/ToolboxAtom';
import { GetHistory } from '../historyaccess/HistoryAccess';
import DxLink from '../dxlink/DxLink';
import { CancelTokenSource } from 'axios';
import { useRecoilValue } from 'recoil';
import LoadingPlaceholder from '../loadingplaceholder/LoadingPlaceholder';
import { PageInfo, PageContentType, ToolboxApp, MatchType } from '../../types';
import AlertBlock from '../markdown/alertblock/AlertBlock';
import ExternalLinkRefresher from '../externallinkrefresher/ExternalLinkRefresher';
import Tag from '../cards/Tag';
import SDKDocPlaceholder from '../embeddable/sdkdocplaceholder/SDKDocPlaceholder';
import SdkRedirecter from '../externallinkrefresher/SdkRedirecter';
import StarIcon from '../staricon/StarIcon';

interface IProps {
	staticPage?: PageInfo;
}

const PageComponentNames = ['relativesitemap', 'markdowneditor', 'videocatalog'];

export default function PageContent(props: IProps) {
	const [pathname, setPathname] = useState<string | undefined>();
	const [pageInfo, setPageInfo] = useState<PageInfo | undefined>();
	const [loadedContent, setLoadedContent] = useState<string | undefined>();
	const [cancelToken, setCancelToken] = useState<CancelTokenSource | undefined>();
	const [scrollAnchorName, setScrollAnchorName] = useState<string | undefined>();
	const toolboxPages = useRecoilValue(toolboxPageItemsAtom);
	const isFirstLoad = useRef(true);

	const thisScrapeMarkdownHeadings = (content?: string) => {
		if (props.staticPage) return;
		return scrapeMarkdownHeadings(content);
	};

	// Constructor
	useEffect(() => {
		if (props.staticPage) return;

		// Register for navigation events
		const updatePathname = () => {
			setPathname(NavMan.pathname);
			setScrollAnchorName(window.location.hash?.replace('#', ''));
		};
		NavMan.onPathnameChanged(updatePathname);

		// Seed pathname
		setPathname(NavMan.pathname);

		// Destructor
		return () => {
			NavMan.onPathnameChanged(updatePathname, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.staticPage) {
			// Initialize/reset contents
			setPageInfo(props.staticPage);
			setPathname(props.staticPage.link);
			setLoadedContent(undefined);
			setScrollAnchorName(undefined);
			thisScrapeMarkdownHeadings('');
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.staticPage]);

	// Page info updated
	useEffect(() => {
		// Don't process if we don't have a page or we're already trying to load the page and it didn't change
		if (!pageInfo || (pageInfo.link === pathname && cancelToken)) return;

		// Update page title
		document.title = pageInfo.title;

		// Clear headings
		thisScrapeMarkdownHeadings('');
		setScrollAnchorName(undefined);

		if (
			pageInfo.contentPath &&
			!loadedContent &&
			(pageInfo.contentType === PageContentType.Markdown || pageInfo.contentType === PageContentType.HTML)
		) {
			if (cancelToken) {
				cancelToken.cancel('New page requested');
			}
			const newCancelToken = AssetLoader.generateCancelToken();
			setCancelToken(newCancelToken);
			AssetLoader.get(pageInfo.contentPath, false, newCancelToken.token)
				.then((content) => {
					setCancelToken(undefined);
					setLoadedContent(content);

					// Scrape markdown for TOC links
					if (pageInfo.contentType === PageContentType.Markdown && !props.staticPage) {
						// Pushes headings to the InPageNav component
						thisScrapeMarkdownHeadings(content);

						// Delay scrolling the first time to allow the rendered DOM to be ready before scrolling.
						// After the first time, do it immediately because no race condition with the DOM exists
						const setAnchorNameCallback = () => setScrollAnchorName(window.location.hash?.replace('#', ''));
						if (isFirstLoad.current) {
							setTimeout(setAnchorNameCallback, 1000);
							isFirstLoad.current = false;
						} else {
							setAnchorNameCallback();
						}
					}
				})
				.catch((err) => {
					if (!AssetLoader.isCancel(err)) console.error(err);
					let message = err.message ? err.message : err;
					let title = 'Error loading content';
					// In production, the S3 website responds with 403 for missing files to intentionally conflate between a lack of permissions and the existence of a file
					if (err.response && (err.response.status === 404 || err.response.status === 403)) {
						message = `404 page not found (${pageInfo.contentPath})`;
						title = 'Page not found';
					}
					const newPageInfo = { ...pageInfo };
					newPageInfo.title = title;
					newPageInfo.contentType = PageContentType.Markdown;
					setPageInfo(newPageInfo);
					setLoadedContent(`:::danger\nSorry, no content was found at this URL. Try the search bar above!
	
	If you followed a link to this page, please use the feedback buttons at the bottom of this page to let us know how you got here.
	
	**Error:** ${message}\n:::`);
					thisScrapeMarkdownHeadings('');
					setCancelToken(undefined);
					setScrollAnchorName(undefined);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageInfo]);

	// Scroll to anchor on change
	useEffect(() => {
		if (scrollAnchorName) document.getElementById(scrollAnchorName)?.scrollIntoView();
	}, [scrollAnchorName]);

	useEffect(() => {
		// Abort if nav has been canceled or if sitemap isn't loaded (don't show a default if we don't know what's in the sitemap yet)
		if (!pathname || !NavMan.isSitemapLoaded) return;

		// Clean up if this is a new page
		if (pathname !== pageInfo?.link) setLoadedContent(undefined);

		// (re)build page info
		const newPageInfo = {
			title: '',
			link: '',
			contentType: PageContentType.Markdown,
		} as PageInfo;

		// 1) component page
		for (const [contentPagePathname, contentPage] of Object.entries(ContentPages)) {
			let page;

			// Find page match
			switch (contentPage.matchType) {
				case MatchType.StartsWith: {
					if (pathname.toLowerCase().startsWith(contentPagePathname.toLowerCase())) page = contentPage;
					break;
				}
				default: {
					if (contentPagePathname.toLowerCase() === pathname.toLowerCase()) page = contentPage;
				}
			}

			if (page) {
				newPageInfo.title = page.title;
				newPageInfo.link = pathname;
				newPageInfo.contentType = PageContentType.PageComponent;
				newPageInfo.contentPath = pathname;
				newPageInfo.component = page.component;
				setPageInfo(newPageInfo);
				return;
			}
		}

		// 2) Handle known pages that are found in the sitemap
		const sitemapPage = NavMan.getPage(pathname);
		if (sitemapPage) {
			if (sitemapPage.link && sitemapPage.link.toLowerCase() !== pathname.toLowerCase()) {
				//update contentPath and redirect if trailing slash is missing on pathname
				newPageInfo.contentType = PageContentType.Redirect;
				newPageInfo.contentPath = sitemapPage.link;
				setPageInfo(newPageInfo);
				return;
			}
			if (sitemapPage.redirect) {
				// Handle non-app redirects and abort further processing
				newPageInfo.contentType = PageContentType.Redirect;
				newPageInfo.contentPath = sitemapPage.redirect;
				setPageInfo(newPageInfo);
				return;
			} else if (sitemapPage.hardRedirect) {
				newPageInfo.contentType = PageContentType.HardRedirect;
				newPageInfo.contentPath = sitemapPage.hardRedirect;
				setPageInfo(newPageInfo);
				return;
			}

			// Copy whitelisted property values to new info
			for (const [key, value] of Object.entries(sitemapPage)) {
				const valueType = typeof value;
				if (['string', 'boolean', 'number', 'bigint'].includes(valueType.toLowerCase())) {
					newPageInfo[key] = value;
				}
			}

			// Handle page components and abort further processing
			if (newPageInfo.component && PageComponentNames.includes(newPageInfo.component.toLowerCase())) {
				newPageInfo.contentType = PageContentType.PageComponent;
				setPageInfo(newPageInfo);
				return;
			}

			// Special cases done, let's try to load it as standard content
			let contentUrl = newPageInfo.link;
			if (contentUrl.endsWith('/')) {
				contentUrl += 'index';
			}
			if (newPageInfo.isHtml) {
				contentUrl += '.html';
				newPageInfo.contentType = PageContentType.HTML;
			} else {
				contentUrl += '.md';
				newPageInfo.contentType = PageContentType.Markdown;
			}
			newPageInfo.contentPath = contentUrl;
			setPageInfo(newPageInfo);
			return;
		}

		// 3) Handle unknown content
		const currentPathname = pathname.toLowerCase();
		const pathnameExtension = currentPathname.substr(currentPathname.lastIndexOf('.') + 1) || '';
		if (currentPathname.endsWith('.pdf')) {
			// PDF viewer
			newPageInfo.title = 'PDF Viewer';
			newPageInfo.contentType = PageContentType.PDF;
			newPageInfo.component = 'PdfViewer';
			newPageInfo.contentPath = pathname;
		} else if (['png', 'jpg', 'gif'].includes(pathnameExtension)) {
			// Image viewer
			newPageInfo.title = 'Image Viewer';
			newPageInfo.contentType = PageContentType.Image;
			newPageInfo.component = 'ContentImage';
			newPageInfo.contentPath = pathname;
		} else if (pathname?.split('/').pop()?.includes('.')) {
			// Last part looks like it has a filename, give a link
			newPageInfo.title = 'Download File';
			newPageInfo.contentType = PageContentType.Download;
			newPageInfo.component = 'ContentLink';
			newPageInfo.contentPath = pathname;
		} else {
			// Try to load the page as markdown, will result in 404 if it's not an unindexed page
			newPageInfo.title = pathname.split('/').pop() || '';
			newPageInfo.contentType = PageContentType.Markdown;
			newPageInfo.contentPath = pathname;
			if (newPageInfo.contentPath.endsWith('/')) newPageInfo.contentPath += 'index';
			newPageInfo.contentPath = `${newPageInfo.contentPath}.md`;
		}

		setPageInfo(newPageInfo);
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageInfo?.link, pathname, NavMan.isSitemapLoaded]);

	/*** BEGIN RENDERING ***/
	if (!pageInfo) return <LoadingPlaceholder />;

	const canBookmark = pageInfo.contentPath && pageInfo.contentPath !== '' && !props.staticPage;
	const bookmarkTitle = (pageInfo.group ? `${pageInfo.group}: ` : '') + pageInfo.title;
	const existingItem = toolboxPages.find((item) =>
		areToolboxItemsEquivalent(item, { appType: ToolboxApp.Pages, title: bookmarkTitle, props: pageInfo }) ? item : undefined
	);
	const isBookmarked = existingItem !== undefined;

	let title = (
		<div className="title-box">
			{canBookmark ? (
				<StarIcon
					starredTooltipText="Saved to toolbox"
					unstarredTooltipText="Removed from toolbox"
					onClick={() => (isBookmarked ? removeItem(existingItem) : AddPage({ title: bookmarkTitle, props: pageInfo }))}
					isStarred={isBookmarked}
				/>
			) : null}
			<h1 className="page-title">{pageInfo.title}</h1>
			{pageInfo.isbeta && <Tag>BETA</Tag>}
		</div>
	);

	let content;
	switch (pageInfo.contentType) {
		case PageContentType.PageComponent: {
			switch (pageInfo.component?.toLowerCase()) {
				case 'relativesitemap': {
					content = <RelativeSitemap />;
					break;
				}
				case 'markdowneditor': {
					content = <MarkdownEditor />;
					break;
				}
				case 'videocatalog': {
					content = <VideoCatalog />;
					break;
				}
				case 'externallinkrefresher': {
					content = <ExternalLinkRefresher />;
					break;
				}
				case 'sdkdocplaceholder': {
					content = <SDKDocPlaceholder />;
					break;
				}
				case 'sdkredirecter': {
					content = <SdkRedirecter />;
					break;
				}
			}
			break;
		}
		case PageContentType.Download: {
			if (!pageInfo.contentPath) break;
			content = <ContentLink url={pageInfo.contentPath} />;
			break;
		}
		case PageContentType.Image: {
			if (!pageInfo.contentPath) break;
			content = <ContentImage src={pageInfo.contentPath} alt={pageInfo.contentPath} />;
			break;
		}
		case PageContentType.PDF: {
			if (!pageInfo.contentPath) break;
			content = <PdfViewer url={pageInfo.contentPath} />;
			break;
		}
		case PageContentType.Redirect:
		case PageContentType.HardRedirect: {
			if (!pageInfo.contentPath) break;
			if (pageInfo.contentType === PageContentType.HardRedirect) {
				window.location.href = pageInfo.contentPath;
			} else {
				GetHistory().replace(pageInfo.contentPath + window.location.hash);
			}
			content = (
				<div>
					Redirecting to <DxLink href={pageInfo.contentPath}>{pageInfo.contentPath}</DxLink>...
				</div>
			);
			break;
		}
		case PageContentType.HTML:
		case PageContentType.Markdown: {
			if (loadedContent && pageInfo.contentType === PageContentType.HTML) {
				content = <div dangerouslySetInnerHTML={{ __html: loadedContent }}></div>;
			} else if (loadedContent && pageInfo.contentType === PageContentType.Markdown) {
				content = <MarkdownDisplay markdown={loadedContent} />;
			} else {
				<LoadingPlaceholder />;
			}
			break;
		}
	}

	let feedbackControl;
	if (content) {
		feedbackControl = <Feedback key={window.location.href} />;
	}

	let preview;
	if (pageInfo.ispreview || pageInfo.isbeta) {
		preview = (
			<AlertBlock title="Preview Content" alertType="warning" collapsible={false}>
				The content on this page is a preview of unreleased functionality. All content is subject to change at any time prior to its
				official release and is not bound by any change management policies. Check out{' '}
				<DxLink href="https://community.genesys.com/communities/genesys-cloud-beta-hq">Beta HQ</DxLink> or open a case at{' '}
				<DxLink href="https://help.mypurecloud.com/articles/contact-genesys-cloud-care/">Genesys Cloud Care</DxLink> for more information.
			</AlertBlock>
		);
	}

	let author = pageInfo.author ? <AuthorInfo author={pageInfo.author} /> : '';
	return (
		<div className={`page-content${canBookmark ? ' bookmarkable' : ''}`}>
			{title}
			{preview}
			{content || <LoadingPlaceholder />}
			{author}
			{feedbackControl}
		</div>
	);
}
