import React from 'react';
import AlertBlock from '../../markdown/alertblock/AlertBlock';

const docInfo = [
	{
		title: '.NET SDK',
		url: 'https://mypurecloud.github.io/platform-client-sdk-dotnet/',
	},
	{
		title: 'iOS SDK',
		url: 'https://mypurecloud.github.io/platform-client-sdk-ios/',
	},
	{
		title: 'GO SDK',
		url: 'https://pkg.go.dev/github.com/MyPureCloud/platform-client-sdk-go',
	},
	{
		title: 'Python SDK',
		url: 'https://mypurecloud.github.io/platform-client-sdk-python/',
	},
	{
		title: 'JavaScript SDK',
		url: 'https://mypurecloud.github.io/platform-client-sdk-javascript/',
	},
	{
		title: 'Java SDK',
		url: 'https://mypurecloud.github.io/platform-client-sdk-java/',
	},
	{
		title: 'JavaScript Guest SDK',
		url: 'https://mypurecloud.github.io/purecloud-guest-chat-client-javascript/',
	},
	{
		title: 'Java Guest SDK',
		url: 'https://mypurecloud.github.io/purecloud-guest-chat-client-java/',
	},
	{
		title: 'Web Messaging Java SDK',
		url: 'https://mypurecloud.github.io/web-messaging-sdk-java/',
	},
	{
		title: 'CLI',
		url: 'https://mypurecloud.github.io/platform-client-sdk-cli/',
	},
];

export default function SDKDocPlaceholder() {
	return (
		<div>
			<AlertBlock title="Documentation moved" alertType="info">
				The SDK documentation has moved! The SDK source code repos on GitHub now self-host their documentation using a GitHub Pages app.
				Refer to the links below for the new locations.
			</AlertBlock>
			{docInfo.map((info, i) => (
				<React.Fragment key={i}>
					<h2>{info.title}</h2>
					<a href={info.url} target="_blank" rel="noreferrer">
						{info.url}
					</a>
				</React.Fragment>
			))}
		</div>
	);
}
